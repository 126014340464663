import { getEnvConfig } from './getEnvConfig';

export interface IAppConfiguration {
    envStamping: IEnvironmentStamping;
}

export interface IEnvironmentStamping {
    logo?: string;
    name?: string;
    color?: string;
}

export const getServerAppConfiguration = (): IAppConfiguration => {
    const config = getEnvConfig();
    const portalConfig: IAppConfiguration = {
        envStamping: {
            color: config.ENV.UI.COLOR,
            logo: config.ENV.UI.LOGO,
            name: config.ENV.UI.NAME
        }
    };
    return portalConfig;
};
