import { getServerAppConfiguration, IAppConfiguration } from '../server/getServerAppConfiguration';
import { isServer } from '../server/isServer';

const getBrowserAppConfiguration = (): IAppConfiguration => {
    const appConfiguration: IAppConfiguration = (window as any).initialAppConfig;
    const portalConfig: IAppConfiguration = {
        envStamping: {
            color: appConfiguration.envStamping?.color,
            logo: appConfiguration.envStamping?.logo,
            name: appConfiguration.envStamping?.name
        }
    };
    return portalConfig;
};

export const getAppConfiguration = (): IAppConfiguration => {
    return isServer() ? getServerAppConfiguration() : getBrowserAppConfiguration();
};
