import React, { useState } from 'react';
import { useAppContext } from './common/context';
import { useTranslation } from 'react-i18next';
import JSONTree from 'react-json-tree';
import jwtDecode from 'jwt-decode';
import { Collapse, Button, CardBody, Card, CardHeader, Label, Input, InputGroup } from 'reactstrap';

const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633'
};

export const LoggedIn = () => {
    const inputref = React.createRef<HTMLInputElement>();
    const copyCodeToClipboard = () => {
        const el = inputref;
        (el.current as any).select();
        document.execCommand('copy');
    };
    const { t } = useTranslation();
    const appContext = useAppContext();
    var decoded = appContext?.user?.accessToken ? jwtDecode(appContext?.user?.accessToken) : {};
    const [statecollaps, setCollapsState] = useState({ profile: true, accessToken: true });

    return (
        <div className="LoggedIn">
            <Label>{t('AccessToken')}</Label>
            <InputGroup className="mb-2">
                <Input innerRef={inputref} readOnly={true} value={appContext?.user?.accessToken} />
                <Button color="primary" onClick={() => copyCodeToClipboard()}>📋</Button>
                <a className="btn btn-danger ml-2" href="/logout">→🚪</a>
            </InputGroup>
            <Card className="mb-2">
                <CardHeader>
                    <Button color="link" className="text-left w-100" onClick={() => setCollapsState({ ...statecollaps, profile: !statecollaps.profile })} >{t('Profile')}</Button>
                </CardHeader>
                <Collapse isOpen={statecollaps.profile}>
                    <CardBody>
                        <JSONTree data={appContext?.user?.profile || {}} theme={theme} invertTheme={true} hideRoot={true} />
                    </CardBody>
                </Collapse>
            </Card>
            <Card className="mb-2">
                <CardHeader>
                    <Button color="link" className="text-left w-100" onClick={() => setCollapsState({ ...statecollaps, accessToken: !statecollaps.accessToken })} >{t('AccessTokenDecoded')}</Button>
                </CardHeader>
                <Collapse isOpen={statecollaps.accessToken}>
                    <CardBody>
                        <JSONTree data={decoded} theme={theme} invertTheme={true} hideRoot={true} />
                    </CardBody>
                </Collapse>
            </Card>
        </div>
    );
};