import React from 'react';

export interface IAppContext {
    isAuthenticated: boolean,
    url?: string,
    user?: any
}

export const AppContext = React.createContext<IAppContext | undefined>(undefined);

export const useAppContext = () => {
    const appContext = React.useContext(AppContext);
    if (appContext === null) {
        throw new Error('useAppContext must be used within a AppContext provider');
    }
    return appContext;
};