import { isServer } from '../server/isServer';
import { serverRouteGuard } from '../server/serverRouteGuard';
import { FrontendRoutes } from './frontendRoutes';

const clientRouteGuard = (route: FrontendRoutes) => {
    // eslint-disable-next-line no-constant-condition
    const context: any = (window as any).appContext;
    if (route !== FrontendRoutes.Home && !context.isAuthenticated) {
        window.location.assign('/login');
    }
};

export const routeGuard = isServer() ? serverRouteGuard : clientRouteGuard;