
import i18next from 'i18next';

i18next.init({
    lng: 'en',
    resources: {
        en: {
            default: {
                CopyrightNotice: '© ABUS | August Bremicker Söhne KG',
                Login: 'Login',
                AccessToken: 'API accesstoken',
                Profile: 'Passport profile',
                AccessTokenDecoded: 'Decoded API accesstoken'
            }
        }
    },
    appendNamespaceToMissingKey: true,
    fallbackNS: 'default',
    defaultNS: 'default',
    react: {
        wait: false,
        transSupportBasicHtmlNodes: true,
        bindI18n: 'loaded'
    } as any
});

export default i18next;