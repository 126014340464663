import React from 'react';
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();
    return (
        <div className="Home d-flex justify-content-center">
            <a className="btn btn-primary" href={'/login'}>
                {t('Login')}
            </ a>
        </div>
    );
};

export default Home;