import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEnvValue } from '../isValidEnvValue';
import { getAppConfiguration } from '../../getAppConfiguration';
import './footer.scss';

const Footer = () => {
    const { t } = useTranslation('default');
    const { envStamping } = getAppConfiguration();
    const color = envStamping.color;

    const footerStyle: React.CSSProperties = {};

    if (isValidEnvValue(color)) {
        footerStyle.backgroundColor = color;
    }

    return (
        <footer className="app-footer container-fluid justify-content-center" style={footerStyle}>
            <div className="d-flex flex-wrap">
                <div className="p-1 flex-grow-md-0 flex-grow-1"><span className="typo-t2 text-white">{t('CopyrightNotice')}</span></div></div>
        </footer>
    );
};

export default Footer;