import i18next from './i18n';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './home';
import Header from './layout/header/header';
import Footer from './layout/footer/footer';
import { I18nextProvider } from 'react-i18next';
import { LoggedIn } from './loggedin';

import '@abussc/abus-styleguide-kit/scss/icons/icons.scss';
import '@abussc/abus-styleguide-kit/scss/fonts/font.scss';
import './app.scss';
import { FrontendRoutes } from './frontendRoutes';
import { routeGuard } from './routeGuard';
import { Main } from './layout/main';

function guardRoute(route: FrontendRoutes, context: any) {
    routeGuard(route, context);
}

const App = (): any => (
    <I18nextProvider i18n={i18next}>
        <div className="App">
            <Header />
            <Main>
                <Switch>
                    <Route exact path={FrontendRoutes.Home} render={(props) => {
                        guardRoute(FrontendRoutes.Home, props.staticContext);
                        return <Home />;
                    }}/>
                    <Route exact path={FrontendRoutes.LoggedIn} render={(props) => {
                        guardRoute(FrontendRoutes.LoggedIn, props.staticContext);
                        return <LoggedIn />;
                    }}/>
                </Switch>
            </Main>
            <Footer />

        </div>
    </I18nextProvider>
);

export default App;
