import App from './frontend/app';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { AppContext } from './frontend/common/context';

hydrate(
    <BrowserRouter>
        <AppContext.Provider value={(window as any).appContext}>
            <App />
        </AppContext.Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept();
}
