export type NODEENV_VALUES = 'production' | 'development';
export type APP_HOST_TYPE = 'staticpage' | 'nodejs';
export interface IEnvConfig {
    AUTH0: {
        APP: {
            CLIENTID: string;
            CLIENT_SECRET: string;
            CALLBACK_URL: string;
            DOMAIN: string;
            AUDIENCE: string;
            LOGOUT_CALLBACK_URL: string;
        }
    };
    PORT: string | number;
    LOG_LEVEL: string;
    LOG_ENABLED: boolean;
    NODE_ENV: NODEENV_VALUES; // will be set from node during startup
    ENV: {
        UI: {
            NAME: string | undefined;
            COLOR: string | undefined;
            LOGO: string | undefined;
        }
    };
}

export const getEnvConfig = (): IEnvConfig => {
    const settings: IEnvConfig = {
        PORT: process.env.APP_PORT || 3100,
        LOG_LEVEL: process.env.PORTAL_LOG_LEVEL || 'info',
        LOG_ENABLED: process.env.PORTAL_LOG_ENABLED !== '0',
        NODE_ENV: process.env.NODE_ENV as NODEENV_VALUES || 'development',
        AUTH0: {
            APP: {
                DOMAIN: process.env.AUTH0_DOMAIN!,
                AUDIENCE: process.env.AUTH0_AUDIENCE!,
                CLIENTID: process.env.AUTH0_CLIENTID!,
                CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET!,
                CALLBACK_URL: process.env.AUTH0_CALLBACK_URL!,
                LOGOUT_CALLBACK_URL: process.env.AUTH0_LOGOUT_CALLBACK_URL!
            }
        },
        ENV: {
            UI: {
                NAME: process.env.ENV_UISTAMP_NAME,
                COLOR: process.env.ENV_UISTAMP_COLOR,
                LOGO: process.env.ENV_UISTAMP_LOGO
            }
        }
    };

    return settings;
};
